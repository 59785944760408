const input = document.querySelector("#signup-phone");
if (input) {
	const iti = window.intlTelInput(input, {
		autoPlaceholder: "off",
		initialCountry: "us",
		separateDialCode: true,
		utilsScript:
			"https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
	});

	const smsSubmitBtn = document.querySelector("#sms-submit-btn");
	const errorMap = [
		"Invalid number",
		"Invalid country code",
		"Too short",
		"Too long",
		"Invalid number",
	];

	const enableSubmitButton = () => {
		const enable = !!input.value.trim();
		if (enable) {
			smsSubmitBtn.hasAttribute("disabled") &&
				smsSubmitBtn.removeAttribute("disabled");
		} else {
			smsSubmitBtn.setAttribute("disabled", "");
		}
	};

	input.addEventListener("change", enableSubmitButton);
	input.addEventListener("keyup", enableSubmitButton);

	const smsOptinForm = document.querySelector("#sms-optin");

	smsSubmitBtn.addEventListener("click", (e) => {
		e.preventDefault();

		if (input.value.trim()) {
			if (iti.isPossibleNumber()) {
				const phoneNumber = iti.getNumber();
				var options = {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					method: "POST",
					body: JSON.stringify({ phoneNumber }),
				};

				fetch("/smsSignup", options)
					.then(function() {
						smsOptinForm.innerHTML = `
						<h3 class="f-medium">✔️ Got it – we'll keep in touch!</h3>
					`;
					})
					.catch(function() {}); // Fail silently - would rather not expose errors on the homepage of all places :)
			} else {
				const errorCode = iti.getValidationError();
				const errorMessage = errorMap[errorCode] || "Invalid number";
				input.setCustomValidity(errorMessage);
				input.reportValidity();
			}
		}
	});
}
